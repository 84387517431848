@import '../../styles/styles';

.section {
  padding: 5rem 0;

  @include mediaQuery(max, 1085px) {
    padding: 3rem 0;
  }
  @include mediaQuery(max, $mobile) {
    padding: 3rem 1rem;
  }
}

.hasGradient {
  background-image: $linearGradient;
}

.content {
  margin: 0 auto;
  max-width: $maxContentWidth;

  @include mediaQuery(max, $mdDesktop) {
    max-width: 915px;
  }

  @include mediaQuery(max, 1023px) {
    max-width: 100%;
    padding: 3rem 0 1rem;
  }
}

.recommendedHeader {
  @include mediaQuery(min, $lgTablet) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;
  }
}

.recommendedLabel {
  @include mediaQuery(min, $lgTablet) {
    max-width: 65%;
  }

  @include mediaQuery(max, 1023px) {
    margin-left: 3rem;
  }

  @include mediaQuery(max, $mobile) {
    margin-left: 1rem;
  }
}

.recommendedSubtitle {
  @include mediaQuery(max, 1085px) {
    max-width: 100%;
  }
}

.recommendedButton {
  @include mediaQuery(max, $lgTablet) {
    display: none;
  }
}

.responsiveLayout {
  :global(.carousel-track) {
    width: 100%;

    @include mediaQuery(max, 1023px) {
      padding-left: 0;
    }

    @include mediaQuery(max, $mobile) {
      padding-left: 0;
    }
  }

  :global(.slider-wrapper) {
    position: static;
  }

  .recommendedLabel {
    @include mediaQuery(max, 1023px) {
      margin-left: 0;
    }
  }

  .content {
    width: 100%;

    @include mediaQuery(max, $mdDesktop) {
      max-width: none;
    }
  }
}
