@import 'styles/styles';

.notFoundSection {
  margin: 0 auto;
  max-width: $maxContentWidth;
}

.mainContent {
  @extend .notFoundSection;
  width: 100%;

  h5 {
    color: $blue;
    margin-bottom: 1rem;
  }

  p {
    font-size: 24px;
    font-weight: 500;
    color: $bodyText;
    line-height: 26px;

    @include mediaQuery(max, $mobile) {
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.notFoundSearch {
  box-shadow: $standardBoxShadow;
  margin: 2rem 0;
}

.linkSection {
  @extend .notFoundSection;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
}

.linkListTitle {
  font-size: 22px;
  font-weight: 600;
}

.linkList {
  flex: 1 1 50%;
  text-align: left;

  ul {
    padding: 0px;
    list-style: none;
    margin: 1.5rem 0 0;
    li {
      margin-bottom: 0.5rem;
      font-size: 16px;
      font-weight: 600;
    }
  }
}
