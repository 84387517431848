@import '../../../styles/styles';

.listTitle {
  font-size: 22px;
  font-weight: 600;
}

.query {
  color: $blue;
  font-size: 16px;
  font-weight: 600;
}

.dates {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: $bodyText;
}

.guests {
  font-size: 14px;
  color: $bodyText;
}

.searchLink {
  color: $bodyText;
}

.listContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.recentList {
  margin-top: 1.5rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.recentItem {
  text-align: left;
  width: 50%;
  margin-bottom: 0.5rem;

  p {
    margin: 0px;
  }
}
