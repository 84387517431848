@import 'styles/styles';

.responsivePage {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .responsivePageLayout {
    flex: 1;
  }
}

// Mobile
// Use .section-reset to avoid these styles when needing to use
// the section tag only for semantic purposes
.responsivePageLayout > section,
.responsivePageLayout > main > section {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  @include mobileGutters;
  @include smallYPadding;
}

// Tablet
@media screen and (min-width: 600px) {
  .responsivePageLayout > section,
  .responsivePageLayout > main > section {
    @include tabletGutters;
    @include smallYPadding;
  }
}

// Desktop
@media screen and (min-width: 1196px) {
  .responsivePageLayout > section,
  .responsivePageLayout > main > section {
    @include desktopGutters;
    @include largeYPadding;
  }
}
